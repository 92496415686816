
import { ActionType } from 'typesafe-actions';

import * as auth from  './auth.actions';
import * as category from './category.actions';

const actions = {
  auth,
  category
}

export type Action = ActionType<typeof actions>;

export default actions