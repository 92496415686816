import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';

import authReducer from './auth.reducer';
import categoryReducer from './category.reducer'

export const rootReducer = combineReducers({
  auth: authReducer,
  category: categoryReducer
});

export type RootState = StateType<typeof rootReducer>;