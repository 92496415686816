import React, { FC } from 'react';
import { List, ListItemText } from '@material-ui/core';
import { ListItemNavLink } from '../../Common';

const AdminNavigation: FC = () => {
  return (
    <List>
      <ListItemNavLink  to="/admin/product">
        <ListItemText>Produkt</ListItemText>
      </ListItemNavLink>
      <ListItemNavLink  to="/admin/category">
        <ListItemText>Kategori</ListItemText>
      </ListItemNavLink>
      <ListItemNavLink  to="/admin/tag">
        <ListItemText>Tag</ListItemText>
      </ListItemNavLink>
    </List>
  );
}

export default AdminNavigation;