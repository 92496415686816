import React, { Component } from 'react';
import { isNullOrUndefined } from 'util';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button } from '@material-ui/core';


interface Props {
  images: any,
  imageCallback: Function,
}

interface State {
  currentImage: number,
  crop: any,
  croppedImageUrl: string,
  croppedImageBlob?: Blob,
  imageRef: any,
  croppedImages: any[],
}


class ImageCrop extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      crop: {
        unit: "%",
        width: 50,
        aspect: 16 / 9
      },
      croppedImageUrl: "",
      croppedImageBlob: undefined,
      imageRef: undefined,
      currentImage: 0,
      croppedImages: [],
    }

    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.onClickDone = this.onClickDone.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
  }

  onImageLoaded(image: any) {
    this.setState({ imageRef: image });
  }

  onCropComplete(crop: any) {
    this.makeClientCrop(crop);
  }

  onCropChange(crop: any, percentCrop: any) {
    this.setState({ crop })
  }

  async makeClientCrop(crop: any) {
    const imageRef = this.state.imageRef;
    if (imageRef && crop.width && crop.height) {
      const croppedImageBlob = await this.getCroppedImg(
        imageRef,
        crop
      ) as Blob;
      const croppedImageUrl = window.URL.createObjectURL(croppedImageBlob);
      this.setState({ croppedImageUrl, croppedImageBlob });
    }
  }

  getCroppedImg(image: any, crop: any) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;

    //canvas for showing
    const ctx = canvas.getContext("2d");
    if (!isNullOrUndefined(ctx)) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }

        //const fileUrl = window.URL.createObjectURL(blob);
        resolve(blob);
      }, "image/jpeg", 1);
    });
  }


  onClickDone() {
    let { croppedImages, croppedImageBlob } = this.state;
    
    if(croppedImageBlob){
      croppedImages.push(new File([croppedImageBlob], "temp.jpg", {type: croppedImageBlob.type, lastModified: Date.now()}));
    }
    console.log(croppedImages);
    this.props.imageCallback(croppedImages);
  }
  

  onClickNext() {
    let { currentImage, croppedImages, croppedImageBlob } = this.state;
    
    if(croppedImageBlob){
      croppedImages.push(new File([croppedImageBlob], "temp.jpg", {type: croppedImageBlob.type, lastModified: Date.now()}));
      currentImage += 1;
    }

    this.setState({ croppedImages, currentImage, croppedImageUrl: "" });
  }

  render() {
    return (
      <div>
        <table>
          <tbody>
          <tr>
            <td>
              <ReactCrop
                style={{ maxWidth: 600 }}
                src={this.props.images[this.state.currentImage]}
                crop={this.state.crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </td>
            <td>

              {
                this.state.croppedImageUrl && (
                  <img alt="Crop" style={{ width: this.state.crop.width, height: this.state.crop.height }} src={this.state.croppedImageUrl} />
                )
              }
            </td>
          </tr>
          <tr>
            <td style={{ display: "flex" }}>
              {
                this.state.currentImage < this.props.images.length - 1 ?
                  <Button variant="contained" color="primary" onClick={this.onClickNext}>Neste</Button>
                  :
                  <Button variant="contained" color="primary" onClick={this.onClickDone}>Ferdig</Button>
              }
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
}


export default ImageCrop;