import React, { FC } from 'react';
import { ListItem, ListItemText, List, Fab } from '@material-ui/core';
import Category from '../../../services/models/category.model';
import { isNullOrUndefined } from 'util';
import theme from '../../../theme';
import styles from './style';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

interface Props {
  parent: Category,
  children?: Category[],
  editHandler: any,
  newHandler: any,
}

const CategoryListItem: FC<Props> = (props) => {
  const classes = styles;

  return (
    <div>
      <ListItem divider key={props.parent.id}>
        <ListItemText primary={props.parent.name} /><ListItemText primary={"Sortering: " + props.parent.sorting_key} />
        <Fab color="primary" aria-label="add" style={classes.fab} size="small" variant={"extended"} onClick={() => props.newHandler(props.parent.id)} >
          <AddIcon />
          Underkategori
        </Fab>
        <Fab color="secondary" aria-label="edit" style={classes.fab} size="small" onClick={() => props.editHandler(props.parent.id)} >
          <EditIcon />
        </Fab>
      </ListItem>
      <List component="div" disablePadding>
        {
          !isNullOrUndefined(props.children) ?
            props.children.map(child =>
              <ListItem key={child.id} style={{ paddingLeft: theme.spacing(4) }}>
                <ListItemText primary={child.name} /><ListItemText primary={"Sortering: " + child.sorting_key} />
                <Fab color="secondary" aria-label="edit" style={classes.fab} size="small" onClick={() => props.editHandler(child.id)} >
                  <EditIcon />
                </Fab>
              </ListItem>
            )
            : null
        }
      </List>
    </div>
  );
};

export default CategoryListItem;