import axios from 'axios';
import { apiUrl } from '../config';

const imageUrl = `${apiUrl}/pictures`;

export default class ImageService {

    public async uploadImages(productId: any, images: any[]) {
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            //TODO: handle sorting key
            /* const request = {
                product_id: productId,
                image_file: image,
                sorting_key: 0,
            } */

            const data = new FormData();
            data.append('product_id', productId);
            data.append('image_file', image);
            data.append('sorting_key', '0');

            let result;

            try {
                console.log(data);
                const response = await axios.post(imageUrl, data);
                result = response.data;
            } catch (error) {
                throw new Error(error);
            }

        }
    }


}