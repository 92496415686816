//import React, { Component, FC } from 'react';
import React, { FC } from 'react';
import { NavLinkProps } from 'react-router-dom';
import { NavListItem, NavListItemLink, NavIndicator } from '../../Common';
//import { Typography } from '@material-ui/core';

interface Props extends NavLinkProps {
  label: string;
}

const HeaderNavigationItem: FC<Props> = (props) => (
  <NavListItem >
    <NavListItemLink {...props} >
      {props.label}
      <NavIndicator />
    </NavListItemLink>
  </NavListItem>
);

HeaderNavigationItem.defaultProps = {
  activeClassName: "nav-item-active",
}

export default HeaderNavigationItem;