import React, { FC, PropsWithChildren } from 'react';
import { Toolbar, Typography, IconButton, useScrollTrigger, Slide, Theme, withTheme } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import HeaderNavigation from './HeaderNavigation';
import { AppBar } from '../../Common';

const HideOnScroll = withTheme(
  ({ children, theme }: PropsWithChildren<{ theme: Theme }>) => {
    const trigger = useScrollTrigger({ target: window });

    if (window.outerWidth <= theme.breakpoints.width('sm')) {
      return (
        <Slide appear={false} direction="down" in={!trigger}>
          <AppBar position="relative">
            {children}
          </AppBar>
        </Slide>
      );
    } else {
      return (
        <AppBar position="fixed">
          {children}
        </AppBar>
      );
    }
  }
);

const Header: FC = () => (
  <HideOnScroll>
    <Toolbar>
      <IconButton edge="start"  color="inherit" aria-label="menu">
        <MenuIcon />
      </IconButton>
      <Typography variant="h6">
        Helica
      </Typography>
      <HeaderNavigation />
    </Toolbar>
  </HideOnScroll>
);

export default Header;