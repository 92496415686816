import { Epic } from "redux-observable";
import { from, of } from 'rxjs';
import { switchMap, filter, map, catchError } from 'rxjs/operators';
import { isActionOf } from "typesafe-actions";

import actions, { Action } from "../actions";
import { RootState } from "../reducers";
import { Dependencies } from ".";


const signInRequestEpic: Epic<Action, Action, RootState, Dependencies> = (action$, store$, { authService }) => 
  action$.pipe(
    filter(isActionOf(actions.auth.signinAction)),
    switchMap((action) => 
      from(authService.signIn(action.payload.username, action.payload.password)).pipe(
        map(actions.auth.signinSuccessAction),
        catchError(error => of(actions.auth.signinFailureAction(error)))
      )
    )
  );

const authenticatedEpic: Epic<Action, Action, RootState, Dependencies> = (action$, store$, { authService }) => 
  action$.pipe(
    filter(isActionOf(actions.auth.authenticatedAction)),
    switchMap((action) => 
      from(authService.getTokens).pipe(
        map(actions.auth.authenticatedFailureAction),
        catchError(error => of(actions.auth.authenticatedFailureAction(error)))
      )
    )
  );


export default [
  signInRequestEpic,
  authenticatedEpic
]