import React, { Component, FormEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import Tag from '../../../services/models/tag.model';
import { Grid, Button } from '@material-ui/core';
import TagService from '../../../services/tag.service';
import { isNullOrUndefined } from 'util';

interface Props {
  parent?: Tag,
  editing?: Tag,
  resetFunc: Function
}

interface State {
  tag: Tag
}

class TagForm extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    if (!isNullOrUndefined(props.editing)) {
      this.state = { tag: props.editing };
    }
    else if (!isNullOrUndefined(props.parent)) {
      this.state = {
        tag: {
          name: "",
          tag_type_id: props.parent.id,
        }
      };
    } else {
      this.state = {
        tag: {
          name: "",
          tag_type_id: undefined,
        }
      };
    }

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange(event: any) {
    const target = event.target;

    const value = target.value;
    const name: string = target.name;

    const { tag } = this.state;
    this.setState({
      tag: {
        ...tag,
        [name]: value
      }
    });

  }

  async onSubmit(event: FormEvent) {
    event.preventDefault();
    const tagService = new TagService()
    let result;
    if (!isNullOrUndefined(this.props.editing)) {
      result = await tagService.editTag(this.state.tag)
    } else {
      result = await tagService.createTag(this.state.tag);
    }
    console.log(result);
    this.props.resetFunc();
  }

  render() {
    const tag = this.state.tag
    return (
      <form style={{ display: 'flex', flexWrap: 'wrap' }} noValidate autoComplete="off" onSubmit={this.onSubmit} >
        <Grid container alignContent='center'>
          <Grid item xs={12}>
            <TextField
              id="nameTextField"
              name="name"
              label="Navn"
              value={tag.name}
              onChange={this.handleChange}
              margin="normal"
            />
          </Grid>
          <Grid>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{  }}
              type="submit"
            >
              Save
            </Button>
          </Grid>

        </Grid>
      </form>
    );
  }
}

export default TagForm;