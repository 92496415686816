import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { RootState } from './reducers';
import actions from './actions';

import App from './App';
import { isNullOrUndefined } from 'util';

interface OwnProps {

}

const mapStateToProps = (state: RootState) => ({
  loaded: !isNullOrUndefined(state.category.categories)
});

const mapDispatchToProps = (dispatch: Dispatch, props: OwnProps) => bindActionCreators({
  getCategories: () => actions.category.cagetoriesFetchAction()
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(App);