import React, { FC } from 'react';

import { NavContainer, NavList } from '../../Common';
import HeaderNavigationItem from './HeaderNavigationItem';

const HeaderNavigation: FC = () => {
  return (
    <NavContainer>
      <NavList>
        <HeaderNavigationItem label="Galleri" exact to="/" />
        <HeaderNavigationItem label="Admin" to="/admin" />
      </NavList>
    </NavContainer>
  );
}

export default HeaderNavigation;