import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';

import { CategoryView } from '../components/Admin/Category';
import { TagView } from '../components/Admin/Tag';
import { ProductView } from '../components/Admin/Product';

class AdminView extends Component {
  render() {
    return (
    <Fragment>
        <Route path="/admin/category" component={CategoryView} />
        <Route path="/admin/tag" component={TagView} />
        <Route path="/admin/product" component={ProductView} />
    </Fragment>
    );
  }
}

export default AdminView;

