import theme from '../../../theme';

const styles = {
    root: {
        maxWidth: 1000,
        display: 'flex',
    },
    gridItem: {
        padding: theme.spacing(2),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
};

export default styles;