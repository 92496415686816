import React, { Component, FormEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import styles from './style';
import Category from '../../../services/models/category.model';
import { Checkbox, Grid, Button } from '@material-ui/core';
import CategoryService from '../../../services/category.service';
import { isNullOrUndefined } from 'util';

interface Props {
  parent?: Category,
  editing?: Category,
  resetFunc: Function
}

interface State {
  category: Category
}

class CategoryForm extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    if(!isNullOrUndefined(props.editing))
    {
      let cat;

      //fix because visible is a number in db and needs to be boolean for checkbox
      if(props.editing.visible){
        cat = {
          ...props.editing,
          visible: true
        }
      }else{
        cat = {
          ...props.editing,
          visible: false
        }
      }

      this.state = { category: cat };
    }
    else if(!isNullOrUndefined(props.parent))
    {
      this.state = {
        category: {
          name: '',
          description: '',
          sorting_key: 1,
          visible: true,
          parent_id: props.parent.id,
        }
      };
    } else {
      this.state = {
        category: {
          name: '',
          description: '',
          sorting_key: 1,
          visible: true,
          parent_id: undefined,
        }
      };
    }

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange(event: any) {
    const target = event.target;

    const value = target.value;
    const name: string = target.name;

    const { category } = this.state;
    this.setState({
      category: {
        ...category,
        [name]: value
      }
    });

  }

  async onSubmit(event: FormEvent) {
    event.preventDefault();
    const categoryService = new CategoryService()
    let result;
    if (!isNullOrUndefined(this.props.editing)) {
      result = await categoryService.editCategory(this.state.category)
    } else {
      result = await categoryService.createCategory(this.state.category);
    }
    console.log(result);
    this.props.resetFunc();
  }

  render() {
    const classes = styles;
    const category = this.state.category
    return (
      <form style={{ display: 'flex', flexWrap: 'wrap' }} noValidate autoComplete="off" onSubmit={this.onSubmit} >
        <Grid container alignContent='center'>
          <Grid item xs={12}>
            <TextField
              id="nameTextField"
              name="name"
              label="Navn"
              value={category.name}
              style={classes.textField}
              onChange={this.handleChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              variant="outlined"
              id="descriptionTextField"
              name="description"
              label="Beskrivelse"
              value={category.description}
              style={classes.textField}
              onChange={this.handleChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              type="number"
              id="sortingTextField"
              name="sorting_key"
              label="Sortering"
              value={category.sorting_key}
              style={{ ...classes.textField, width: 100 }}
              onChange={this.handleChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              name="visible"
              onChange={this.handleChange}
              checked={this.state.category.visible}
              color="primary"
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />Synlig på nettsiden?

          </Grid>

          <Grid>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={classes.fab}
              type="submit"
            >
              Save
            </Button>
          </Grid>

        </Grid>
      </form>
    );
  }
}

export default CategoryForm;