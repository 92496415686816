import React, { Component } from 'react';
import { Grid, FormControl, InputLabel, Select, Chip, FormGroup, FormLabel, FormControlLabel, Checkbox, Input, InputAdornment, TextField, Button, Backdrop, Modal, Fade } from '@material-ui/core';
import Product from '../../../services/models/product.model';
import Category from '../../../services/models/category.model';
import Tag from '../../../services/models/tag.model';
import { isNullOrUndefined } from 'util';
import theme from '../../../theme';
import styles from './style';
import ProductService from '../../../services/product.service';
import ImageService from '../../../services/image.service';
import { DropzoneArea } from 'material-ui-dropzone';
import ImageCrop from './ImageCrop';


interface Props {
  categories: Category[],
  tags: Tag[],
  editing?: Product,
  resetFunc: Function,
}

interface State {
  product: Product,
  selectedTags: number[],
  files: any[],
  croppedImages: any[],
  isCropOpen: boolean,
  croppingDone: boolean,
}

class ProductForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      product: {
        id: undefined,
        categoryId: 0,
        subCategoryId: undefined,
        price: 0,
        description: "",
        details: "",
        internalNote: "",
        scan1: undefined,
        scan2: undefined,
        scan3: undefined,
        priority: 0,
        visible: 1,
        slider: 0,
      },
      selectedTags: [],
      files: [],
      croppedImages: [],
      isCropOpen: false,
      croppingDone: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleTags = this.handleTags.bind(this);
    this.handleScanChange = this.handleScanChange.bind(this);
    this.removeSelectedTag = this.removeSelectedTag.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelectFiles = this.onSelectFiles.bind(this);
    this.openCropModal = this.openCropModal.bind(this);
    this.setImages = this.setImages.bind(this);
  }

  async onSubmit(event: any) {
    event.preventDefault();
    const prodService = new ProductService();
    const imageService = new ImageService();

    const productResult = await prodService.createProduct(this.state.product);

    const tagResult = await prodService.addTags(productResult.id, this.state.selectedTags);

    const imageResult = await imageService.uploadImages(productResult.id, this.state.croppedImages);

    this.setState({
      product: {
        id: undefined,
        categoryId: 0,
        subCategoryId: undefined,
        price: 0,
        description: '',
        details: '',
        internalNote: '',
        scan1: undefined,
        scan2: undefined,
        scan3: undefined,
        priority: 0,
        visible: 1,
        slider: 0,
      },
      selectedTags: [],
      files: [],
      croppedImages: [],
      isCropOpen: true,
    });

  }

  handleChange(event: any) {
    const target = event.target;

    const value = target.value;
    const name: string = target.name;
    const { product } = this.state;
    this.setState({
      product: {
        ...product,
        [name]: value
      }
    });

  }

  handleTags(event: any) {
    let selectedTags = this.state.selectedTags;
    const selectedTag = event.target.value as number;

    if (event.target.checked) {
      selectedTags.push(selectedTag);
    } else {
      selectedTags = selectedTags.filter(tag => tag !== selectedTag)
    }
    this.setState({ selectedTags })
  }

  handleScanChange(event: any) {
    const target = event.target;
    const file = target.files[0];
    const value = file;
    console.log(value);
    const name: string = target.name;
    const { product } = this.state;
    this.setState({
      product: {
        ...product,
        [name]: value
      }
    });

  }


  removeSelectedTag(id?: number) {
    let idStr: any;
    if (!isNullOrUndefined(id)) {
      idStr = id.toString();
    }
    let selectedTags = this.state.selectedTags;
    selectedTags = selectedTags.filter(tag => tag !== idStr);

    this.setState({ selectedTags });
  }

  onSelectFiles(files: any[]) {
    let newFiles: string[] = [];

    files.forEach(file => {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        newFiles.push(reader.result as string)
      );
      reader.readAsDataURL(file);
    });
    this.setState({ files: newFiles, croppingDone: false });
  }

  setImages(images: string[]) {
    console.log(images);
    this.setState({ croppedImages: images, files: [], isCropOpen: false, croppingDone: true });
  }

  openCropModal() {
    this.setState({ isCropOpen: true });
  }

  render() {
    const product = this.state.product;
    const { categories, tags } = this.props;
    const isVisible = product.visible == 1;

    let selectedTags: Tag[] = [];
    Array.from(this.state.selectedTags).forEach(tagId => {
      const t = tags.find(tag => tag.id == Number(tagId));
      if (!isNullOrUndefined(t)) {
        selectedTags.push(t)
      }
    });

    const tagTypes = tags.filter(tag => isNullOrUndefined(tag.tag_type_id));

    return (
      <div style={styles.root}>
        <form noValidate autoComplete="off" onSubmit={this.onSubmit} >
          <Grid container>

            <Grid item xs={8} style={styles.gridItem} >
              <FormControl>
                <InputLabel shrink>Pris</InputLabel>
                <Input
                  id="price"
                  name="price"
                  type="number"
                  style={{ width: 120 }}
                  value={product.price}
                  onChange={this.handleChange}
                  startAdornment={<InputAdornment position="start">NOK</InputAdornment>}
                  endAdornment={<InputAdornment position="end">,-</InputAdornment>}
                />
              </FormControl>
            </Grid>

            <Grid item xs={4} style={styles.gridItem}>
              <FormControl>
                <FormControlLabel
                  control={<Checkbox name="visible" checked={isVisible} onChange={this.handleChange} value={!isVisible ? 1 : 0} />}
                  label="Synlig på nettsiden?"
                />
              </FormControl>
            </Grid>

            <Grid item xs={4} style={styles.gridItem}>
              <FormControl>
                <TextField
                  id="description"
                  name="description"
                  label="Beskrivelse"
                  multiline
                  rows="4"
                  value={product.description}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={4} style={styles.gridItem}>
              <FormControl>
                <TextField
                  id="details"
                  name="details"
                  label="Detaljer"
                  multiline
                  rows="4"
                  value={product.details}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={4} style={styles.gridItem}>
              <FormControl>
                <TextField
                  id="internalNote"
                  name="internalNote"
                  label="Interne notater"
                  multiline
                  rows="4"
                  value={product.internalNote}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={2} style={styles.gridItem} >
              <FormControl style={undefined}>
                <FormLabel>
                  Kategori
                </FormLabel>
                <Select
                  multiple
                  native
                  variant="outlined"
                  value={[product.categoryId]}
                  onChange={this.handleChange}
                  name="categoryId"
                >
                  {
                    !isNullOrUndefined(categories) ?
                      categories.filter(cat => isNullOrUndefined(cat.parent_id)).map(cat => (
                        <option key={cat.id} value={cat.id}>
                          {cat.name}
                        </option>
                      )) : null

                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2} style={styles.gridItem} >
              <FormControl style={undefined}>
                <FormLabel>
                  Underkategori
                </FormLabel>
                <Select
                  multiple
                  native
                  variant="outlined"
                  value={[product.subCategoryId]}
                  onChange={this.handleChange}
                  name="subCategoryId"
                >
                  {
                    !isNullOrUndefined(categories) ?
                      categories.filter(cat => cat.parent_id == product.categoryId).map(cat => (
                        <option key={cat.id} value={cat.id}>
                          {cat.name}
                        </option>
                      )) : null

                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4} style={styles.gridItem}>
              <FormControl>
                <TextField
                  style={{ width: 100 }}
                  id="priority"
                  name="priority"
                  label="Prioritet"
                  type="number"
                  value={product.priority}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} style={styles.gridItem}>
              {
                selectedTags.map(tag => (
                  <Chip key={tag.id} label={tag.name} onDelete={() => this.removeSelectedTag(tag.id)} color="primary" />
                ))
              }
            </Grid>

            <Grid item xs={12} style={styles.gridItem}>
              <FormControl style={{ display: 'flex', flexDirection: 'row' }}>
                <FormLabel>Tags</FormLabel>
                {
                  tagTypes.map(tagType => (
                    <FormGroup key={tagType.id} style={{ margin: theme.spacing(3) }} >
                      <FormLabel><b>{tagType.name}</b></FormLabel>
                      {
                        tags.filter(tag => tag.tag_type_id == tagType.id).map(tag => (
                          <FormControlLabel
                            control={<Checkbox checked={this.state.selectedTags.some(t => t == tag.id)} onChange={this.handleTags} value={tag.id} />}
                            label={tag.name}
                            key={tag.id}
                          />
                        ))
                      }
                    </FormGroup>
                  ))
                }
              </FormControl>
            </Grid>

            <Grid item xs={12} style={styles.gridItem}>
              <FormLabel>Bilder</FormLabel>
              <DropzoneArea key={this.state.croppingDone ? 1 : 2} onChange={this.onSelectFiles} maxFileSize={10000000} filesLimit={10} initialFiles={this.state.files} />
            </Grid>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={styles.modal}
              open={this.state.isCropOpen}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={this.state.isCropOpen}>
                <div style={styles.paper}>
                  <ImageCrop images={this.state.files} imageCallback={this.setImages} />
                </div>
              </Fade>
            </Modal>

            {
              !this.state.croppingDone && (
                <Grid item xs={12} style={styles.gridItem} >
                  <Button variant="contained" color="secondary" onClick={this.openCropModal} >Behandle bilder</Button>
                </Grid>
              )
            }

            <Grid item xs={4} style={styles.gridItem} >
              <FormLabel>Scan 1</FormLabel>
              <input type="file" onChange={this.handleScanChange} name="scan1" />
            </Grid>
            <Grid item xs={4} style={styles.gridItem} >
              <FormLabel>Scan 2</FormLabel>
              <input type="file" onChange={this.handleScanChange} name="scan2" />
            </Grid>
            <Grid item xs={4} style={styles.gridItem} >
              <FormLabel>Scan 3</FormLabel>
              <input type="file" onChange={this.handleScanChange} name="scan3" />
            </Grid>

            <Grid item xs={12} style={styles.gridItem}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={!this.state.croppingDone}
                style={undefined}
                type="submit"
              >
                Save
            </Button>
            </Grid>

          </Grid>
        </form>
      </div>
    );
  }
}

export default ProductForm;