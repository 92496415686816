import axios from 'axios';
import { apiUrl } from '../config';

import Product from './models/product.model'

const productUrl = `${apiUrl}/products`;


export default class ProductService {

  public async createProduct(product: Product) {
    let result;
    const prod = {
      category_id: product.categoryId,
      sub_category_id: product.subCategoryId,
      price: +product.price,
      description: product.description,
      details: product.details,
      internal_note: product.internalNote,
      priority: +product.priority,
      visible: +product.visible,
      show_in_slider: +product.slider,
    }
    try {
      const response = await axios.post(productUrl, prod);
      result = response.data;
    } catch (error) {
      throw new Error(error);
    }

    this.addScans(result.id, product.scan1, product.scan2, product.scan3);

    return result;
  }

  public async addScans(productId: number, scan1?: any, scan2?: any, scan3?: any) {
    if (scan1) {
      try {
        const data = new FormData();
        data.append('product_id', String(productId));
        data.append('scan_file', scan1);
        data.append('scan_number', '1');
        const response = await axios.post(productUrl + '/scans', data);
        console.log(response.data);
      } catch (error) {
        throw new Error(error);
      }
    }
    if (scan2) {
      try {
        const data = new FormData();
        data.append('product_id', String(productId));
        data.append('scan_file', scan2);
        data.append('scan_number', '2');
        const response = await axios.post(productUrl + '/scans', data);
        console.log(response.data);
      } catch (error) {
        throw new Error(error);
      }
    }
    if (scan3) {
      try {
        const data = new FormData();
        data.append('product_id', String(productId));
        data.append('scan_file', scan3);
        data.append('scan_number', '3');
        const response = await axios.post(productUrl + '/scans', data);
        console.log(response.data);
      } catch (error) {
        throw new Error(error);
      }
    }
  }

  public async addTags(productId: number, tags: number[]) {
    let result;

    try {
      const req = { tags: tags }
      const response = await axios.post(productUrl + '/' + productId + '/tags', req);
      result = response.data;
    } catch (error) {
      throw new Error(error);
    }

    return result;
  }

}