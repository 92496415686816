import React from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';

import Category from '../../../services/models/category.model';
import { isNullOrUndefined } from 'util';

import CategoryListItem from './CategoryListItem';
import styles from './style';
import { ListItem, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';


interface Props {
  categories?: Category[],
  editHandler: Function,
  newHandler: Function,
}

export default function CategoryList(props: Props) {
  const classes = styles;
  let parents;
  let children: Category[];

  if (!isNullOrUndefined(props.categories)) {
    parents = props.categories.filter(cat => cat.parent_id == null);
    children = props.categories.filter(cat => cat.parent_id != null)
  }

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Kategorier
        </ListSubheader>
      }
      style={classes.root}
    >
      {
        !isNullOrUndefined(parents) ?
          parents.map(parent =>
            <CategoryListItem key={parent.id} parent={parent} editHandler={props.editHandler} newHandler={props.newHandler} children={children.filter(cat => cat.parent_id === parent.id)} />
          )
          : null
      }
      <ListItem key="addBtn">
        <Fab color="primary" aria-label="add" style={classes.fab} variant="extended" onClick={() => props.newHandler(undefined)} >
          <AddIcon />
          Kategori
        </Fab>
      </ListItem>
    </List>
  );
}