import React from 'react';

import { Drawer as MDrawer, Theme, AppBar as Header, ListItem } from '@material-ui/core';
import { withStyles, styled } from '@material-ui/styles';
import { NavLink, NavLinkProps } from 'react-router-dom';

const drawerWidth = 240;

export const ToolbarSpacer = styled('div')<Theme>(({ theme }) => ({
  minHeight: '65px',

  [theme.breakpoints.down('xs')]: {
    minHeight: '95px'
  }
}));


export const Drawer = withStyles({
  root: {
    width: drawerWidth,
    flexShrink: 0
  },
  paper: {
    width: drawerWidth
  }
})(MDrawer);

export const AppBar = withStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1
  },

  [theme.breakpoints.down('xs')]: {
    root: {
      minHeight: '95px'
    }
  }
}))(Header);


export const NavContainer = styled('nav')<Theme>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    marginLeft: 'auto',
    marginRight: '100px'
  },
  [theme.breakpoints.down('xs')]: {
    top: '45px',
    position: 'absolute',
    right: 0,
    left: 0
  }
}));


export const NavList = styled('ul')<Theme>(({ theme }) => ({
  margin: 0,
  padding: 0,
  color: '#fff',
  [theme.breakpoints.down('xs')]: {
    display: 'flex',
    justifyContent: 'space-evenly'
  }
}));


export const NavListItem = styled('li')({
  display: 'inline-block'
})


export const NavListItemLink = styled(NavLink)<Theme>(({ theme }) => ({
  textDecoration: 'none',
  color: '#fff',
  position: 'relative',
  display: 'inline-block',
  padding: '0 15px',
  fontSize: '16px',

  '&:hover': {
    color: '#fff'
  },

  'a:hover &': {
    color: '#9e9e9e'
  },

  [theme.breakpoints.up('sm')]: {
    lineHeight: 3.99
  },

  [theme.breakpoints.down('xs')]: {
    lineHeight: 3.13
  }
}));


export const NavIndicator = styled('span')({
  background: '#fff',
  bottom: 0,
  height: '4px',
  position: 'absolute',
  transform: 'scaleY(0)',
  transformOrigin: 'bottom',
  transition: 'transform 235ms cubic-bezier(.4,0,.2,1)',
  
  right: '15px',
  left: '15px',

  'a.nav-item-active &': {
    transform: 'scaleY(1)'
  }
});

export const Content = styled('main')<Theme>(({ theme }) => ({
  flexShrink: 1,
  padding: theme.spacing(3)
}));

export const ListItemNavLink = withStyles((theme: Theme) => ({
  root: {
    '&.active': {
      backgroundColor: theme.palette.grey["200"]
    }
  }
}))((props: NavLinkProps) => (
  <ListItem {...props} button component={NavLink} activeClassName="active"></ListItem>
));