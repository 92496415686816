import { createReducer, ActionType } from 'typesafe-actions';

import * as authActions from '../actions/auth.actions';

type AuthActions = ActionType<typeof authActions>;

interface AuthState {
  expires?: number;
  error?: Error;
  loading: boolean;
};

const defaultStore: AuthState = {
  loading: false
}

export default createReducer<AuthState, AuthActions>(defaultStore)
  .handleAction(authActions.signinAction, (state, action) => ({ loading: true }))
  .handleAction([authActions.authenticatedSuccessAction, authActions.signinSuccessAction], (state, { payload }) => ({
    loading: false,
    expires: payload.expires
  }))
  .handleAction([authActions.authenticatedFailureAction, authActions.signinFailureAction], (state, { payload }) => ({
    loading: false,
    error: payload.error
  }))
  .handleAction(authActions.clearStore, (state) => ({ loading: false }));

