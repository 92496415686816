import { Epic } from "redux-observable";
import { from, of } from 'rxjs';
import { switchMap, filter, map, catchError } from 'rxjs/operators';
import { isActionOf } from "typesafe-actions";

import actions, { Action } from "../actions";
import { RootState } from "../reducers";
import { Dependencies } from ".";


const fetchCategoriesEpic: Epic<Action, Action, RootState, Dependencies> = (action$, store$, { categoryService }) => 
  action$.pipe(
    filter(isActionOf(actions.category.cagetoriesFetchAction)),
    switchMap(() => 
      from(categoryService.getCategories()).pipe(
        map(actions.category.cagetoriesFetchSuccessAction),
        catchError(error => of(actions.category.cagetoriesFetchFailureAction(error)))
      )
    )
  );


export default [
  fetchCategoriesEpic
]