import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  /*
  palette: {
    primary: {
      main: '#212121',
      light: '#484848',
      dark: '#000000'
    },
    secondary: {
      main: '#19857b',
      light: '#ad1357',
      dark: '#e35083'
    },
    background: {
      default: '#fff',
      paper: '#fafafa',
    },
  },
  */
});

export default theme;

/* <color name="secondaryDarkColor">#78002e</color>

<color name="primaryTextColor">#ffffff</color>

<color name="secondaryTextColor">#ffffff</color> */