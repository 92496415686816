import React, { Component } from 'react';
import Category from "../services/models/category.model";
import ProductForm from "../components/Admin/Product/ProductForm";
import { isNullOrUndefined } from "util";
import Tag from "../services/models/tag.model";
import Product from "../services/models/product.model";
import TagService from '../services/tag.service';


interface Props {
  categories?: Category[]
}

interface State {
  tags: Tag[],
  showForm: boolean,
  editing?: Product,
}

class ProductView extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      tags: [],
      showForm: true,
      editing: undefined,
    };

  }

  componentWillMount() {
    this.fetchTags();
  }

  async fetchTags() {
    const tagService = new TagService();

    let result;

    try {
        result = await tagService.getTags();
        this.setState({ tags: result })
    } catch (error) {
        console.log(error)
    }
  }

  productForm() {

    if (this.state.showForm && !isNullOrUndefined(this.props.categories)) {
      return (
        <ProductForm categories={this.props.categories} tags={this.state.tags} editing={this.state.editing} resetFunc={() => this.removeForm()} />
      );
    } else {
      return null;
    }

  }

  removeForm() {
    this.setState({ showForm: false })
    this.setState({
      editing: undefined,
    })
  }

  render() {
    let helpText;
    if (this.state.showForm) {
      if (!isNullOrUndefined(this.state.editing)) {
        helpText = `Endre produktet med ID ${this.state.editing.id}`
      } else {
        helpText = `Lag nytt produkt`
      }
    }

    return (
      <div>
        <h2>{helpText}</h2>
        {this.productForm()}
      </div>
    );
  }
}

export default ProductView;