import React, { Component } from 'react';

import CategoryList from '../components/Admin/Category/CategoryList';

import Category from '../services/models/category.model';
import CategoryForm from '../components/Admin/Category/CategoryForm';

import { isNullOrUndefined } from 'util';

interface Props {
  categories?: Category[]
}

interface State {
  selectedParent?: Category,
  showForm: boolean,
  editing?: Category,
}

class CategoryView extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedParent: undefined,
      showForm: false,
      editing: undefined,
    };

    this.editHandler = this.editHandler.bind(this);
    this.newHandler = this.newHandler.bind(this);
  }

  editHandler(id: number) {
    this.setState({ showForm: false, editing: undefined, selectedParent: undefined })

    if (!isNullOrUndefined(this.props.categories)) {
      this.setState({
        editing: this.props.categories.find(cat => cat.id === id),
        showForm: true
      });
    }
    //console.log(this.state);
    // funker ikke første gang, why??
  }

  newHandler(parent_id?: number) {
    this.setState({ showForm: false, editing: undefined, selectedParent: undefined })

    if (!isNullOrUndefined(this.props.categories)) {
      this.setState({
        selectedParent: this.props.categories.find(cat => cat.id === parent_id),
        showForm: true
      });
    }
    console.log(this.state);
  }

  categoryForm() {

    if (this.state.showForm) {

      if (!isNullOrUndefined(this.state.selectedParent) && !isNullOrUndefined(this.state.editing)) {
        console.log('Both selectedParent and editing are set, this shouldnt happen..');
        return (<div>Error</div>);
      } else {
        return (
          <CategoryForm editing={this.state.editing} parent={this.state.selectedParent} resetFunc={() => this.removeForm()} />
        );
      }

    }
    else {
      return null;
    }

  }

  removeForm() {
    this.setState({ showForm: false })
    this.setState({
      editing: undefined,
      selectedParent: undefined
    })
  }

  render() {

    const { categories } = this.props;
    let helpText;
    if (this.state.showForm) {
      if (!isNullOrUndefined(this.state.selectedParent)) {
        helpText = `Lag ny underkategori for ${this.state.selectedParent.name}.`
      } else if (!isNullOrUndefined(this.state.editing)) {
        helpText = `Endre kategorien ${this.state.editing.name} med id ${this.state.editing.id}`
      } else {
        helpText = `Lag ny kategori`
      }
    }

    return (
      <div>
        <CategoryList categories={categories} editHandler={this.editHandler} newHandler={this.newHandler} />
        <h2>{helpText}</h2>
        {this.categoryForm()}
      </div>
    );
  }
}

export default CategoryView;