import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import AdminNavigation from './AdminNavigation';
import { ToolbarSpacer, Drawer } from '../../Common';

const DrawerNavigation: FC = () => {
  return (
    <Drawer
      variant="permanent"
      >
      <ToolbarSpacer />
      <Route path="/admin" component={AdminNavigation} />
    </Drawer>
  );
}

export default DrawerNavigation;

