import React from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';

import { isNullOrUndefined } from 'util';

import TagListItem from './TagListItem';

import { ListItem, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Tag from '../../../services/models/tag.model';
import theme from '../../../theme';


interface Props {
  tags?: Tag[],
  editHandler: Function,
  newHandler: Function,
}

export default function TagList(props: Props) {
  let tagTypes;
  let tags: Tag[];

  if (!isNullOrUndefined(props.tags)) {
    tagTypes = props.tags.filter(tag => tag.tag_type_id == null);
    tags = props.tags.filter(tag => tag.tag_type_id != null)
  }

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Tags
        </ListSubheader>
      }
      style={{ width: 700, backgroundColor: theme.palette.background.default }}
    >
      {
        !isNullOrUndefined(tagTypes) ?
          tagTypes.map(tagType =>
            <TagListItem key={tagType.id} tagType={tagType} editHandler={props.editHandler} newHandler={props.newHandler} tags={tags.filter(tag => tag.tag_type_id === tagType.id)} />
          )
          : null
      }
      <ListItem key="addBtn">
        <Fab color="primary" aria-label="add" style={{ marginRight: theme.spacing(1) }} variant="extended" onClick={() => props.newHandler(undefined)} >
          <AddIcon />
          TagType
        </Fab>
      </ListItem>
    </List>
  );
}