import { createReducer } from 'typesafe-actions';

import actions, { Action } from "../actions";
import Category from '../services/models/category.model';
import CategoryService from '../services/category.service';

const categoryService = new CategoryService();

interface CategoryState {
  categories?: Category[],
  loading: boolean,
  error?: string
};

const defaultStore: CategoryState = {
  loading: false,
  categories: categoryService.getCategoriesCache()
}

export default createReducer<CategoryState, Action>(defaultStore)
  .handleAction(actions.category.cagetoriesFetchAction, (state) => ({
    ...state,
    error: undefined,
    loading: true
  }))
  .handleAction(actions.category.cagetoriesFetchSuccessAction, (state, { payload: { categories } }) => {
    categoryService.setCategoriesCache(categories);
    
    return {
      loading: false,
      categories
    }
  })
  .handleAction(actions.category.cagetoriesFetchFailureAction, (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error: error.message
  }))

