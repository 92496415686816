import { createAction } from 'typesafe-actions';

import { Tokens } from '../interfaces/tokens.interface';
import { 
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  AUTHENTICATED_CHECK,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAILURE,
  CLEAR_STORE
} from './constants/auth.constants';



export const signinAction = createAction(LOGIN_REQUEST, resolve => (username: string, password: string) => resolve({ username, password }));

export const signinSuccessAction = createAction(LOGIN_SUCCESS, resolve => (tokens: Tokens) => resolve({ expires: tokens.expires }));

export const signinFailureAction = createAction(LOGIN_FAILURE, resolve => (error: Error) => resolve({ error }));


export const authenticatedAction = createAction(AUTHENTICATED_CHECK, resolve => () => resolve());

export const authenticatedSuccessAction = createAction(AUTHENTICATED_SUCCESS, resolve => (tokens: Tokens) => resolve({ expires: tokens.expires }));

export const authenticatedFailureAction = createAction(AUTHENTICATED_FAILURE, resolve => (error: Error) => resolve({ error }));

export const clearStore = createAction(CLEAR_STORE);

