import { createAction } from 'typesafe-actions';

import { 
  CATEGORIES_FETCH,
  CATEGORIES_FETCH_SUCCESS,
  CATEGORIES_FETCH_FAILURE
} from './constants/category.constants';
import Category from '../services/models/category.model';



export const cagetoriesFetchAction = createAction(CATEGORIES_FETCH);

export const cagetoriesFetchSuccessAction = createAction(CATEGORIES_FETCH_SUCCESS, resolve => (categories: Category[]) => resolve({ categories: categories }));

export const cagetoriesFetchFailureAction = createAction(CATEGORIES_FETCH_FAILURE, resolve => (error: Error) => resolve({ error: error }));