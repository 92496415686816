
export const LOGIN_REQUEST = '@@auth/SIGNIN_REQUEST';
export const LOGIN_SUCCESS = '@@auth/SIGNIN_SUCCESS';
export const LOGIN_FAILURE = '@@auth/SIGNIN_FAILURE';

export const AUTHENTICATED_CHECK = '@@auth/AUTHENTICATED_CHECK';
export const AUTHENTICATED_SUCCESS = '@@auth/AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAILURE = '@@auth/AUTHENTICATED_FAILURE';

export const CLEAR_STORE = '@@auth/CLEAR_STORE';
