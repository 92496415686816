import axios from 'axios';
import { apiUrl } from '../config';

import Tag from '../services/models/tag.model';

const tagUrl = `${apiUrl}/tags`;

export default class TagService {

  public async getTags()
  {
    let result;
  
    try {
      const response = await axios.get(tagUrl);
      result = response.data;
    } catch (error) {
      throw new Error(error);
    }

    return result;
  }

  public async createTag(tag: Tag)
  {
    let result;
    try {
      const response = await axios.post(tagUrl, tag);
      result = response.data;
    } catch (error) {
      throw new Error(error);
    }

    return result;
  }

  public async editTag(tag: Tag) {
    let result;
    const editUrl = `${tagUrl}/${tag.id}`;
    try {
      const response = await axios.put<Tag>(editUrl, tag)
      result = response;
    } catch (error) {
      throw new Error(error);
    }

    return result;
  }

}