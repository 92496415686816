import React, { FC } from 'react';
import { ListItem, ListItemText, List, Fab } from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import theme from '../../../theme';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Tag from '../../../services/models/tag.model';

interface Props {
  tagType: Tag,
  tags?: Tag[],
  editHandler: any,
  newHandler: any,
}

const TagListItem: FC<Props> = (props) => {
    const { tagType, tags } = props

  return (
    <div>
      <ListItem divider key={tagType.id}>
        <ListItemText primary={tagType.name} />
        <Fab color="primary" aria-label="add" style={{ paddingLeft: theme.spacing(1) }} size="small" variant={"extended"} onClick={() => props.newHandler(tagType.id)} >
          <AddIcon />
          Tag
        </Fab>
        <Fab color="secondary" aria-label="edit" style={{ paddingLeft: theme.spacing(1) }} size="small" onClick={() => props.editHandler(tagType.id)} >
          <EditIcon />
        </Fab>
      </ListItem>
      <List component="div" disablePadding>
        {
          !isNullOrUndefined(tags) ?
            tags.map(tag =>
              <ListItem key={tag.id} style={{ paddingLeft: theme.spacing(4) }}>
                <ListItemText primary={tag.name} />
                <Fab color="secondary" aria-label="edit" style={{ paddingLeft: theme.spacing(1) }} size="small" onClick={() => props.editHandler(tag.id)} >
                  <EditIcon />
                </Fab>
              </ListItem>
            )
            : null
        }
      </List>
    </div>
  );
};

export default TagListItem;