import { combineEpics } from "redux-observable";

import { Action } from "../actions";
import { RootState } from "../reducers";

import AuthService from "../services/auth.service";
import CategoryService from "../services/category.service";

import authEpics from './auth.epic'
import categoryEpics from './category.epic';


export interface Dependencies {
  authService: AuthService,
  categoryService: CategoryService
}

export const dependencies: Dependencies = {
  authService: new AuthService(),
  categoryService: new CategoryService()
}

export default combineEpics<Action, Action, RootState, Dependencies>(
  ...authEpics,
  ...categoryEpics
)