import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from "redux-observable";
import logger from 'redux-logger';

import { Action } from './actions';

import { RootState, rootReducer } from './reducers';
import epics, { dependencies } from './epics'


declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function;
  }
}

const composeEnhancers = (
  window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) || compose;

const epicMiddleware = createEpicMiddleware<Action, Action, RootState>({
  dependencies
});



const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      epicMiddleware,
      logger
    )
  )
);

epicMiddleware.run(epics);

export default store;