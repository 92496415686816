import axios from 'axios';

import { apiUrl } from '../config';
import Category from './models/category.model'
import { isNullOrUndefined } from 'util';

const categoryUrl = `${apiUrl}/categories`;

export default class CategoryService {

  public async getCategories()
  {
    let result: Category[];
  
    try {
      const response = await axios.get<Category[]>(categoryUrl);
      result = response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Could not get categories from server');
    }

    return result;
  }

  public async getCategory(id: number)
  {
    let result: Category;

    try {
      const response = await axios.get<Category>(`${categoryUrl}/${id}`);
      result = response.data;
    } catch (error) {
      console.error(error);
      throw new Error(`Could not get category with id ${id}`);
    }

    return result;
  }

  public async getSubCatsByCat(categoryId:number)
  {
    let result;
    try {
      const response = await axios.get(`${categoryUrl}/${categoryId}/subcategories`);
      result = response.data;
    } catch (error) {
      throw new Error(error);
    }

    return result;
  }

  public async createCategory(category: Category)
  {
    let result: Category;
    try {
      const response = await axios.post<Category>(categoryUrl, category);
      result = response.data;
    } catch (error) {
      throw new Error(error);
    }

    return result;
  }

  public async editCategory(category: Category) {
    let result;
    const editUrl = `${categoryUrl}/${category.id}`;
    try {
      const response = await axios.put<Category>(editUrl, category)
      result = response;
    } catch (error) {
      throw new Error(error);
    }

    return result;
  }

  public getCategoriesCache() {
    const catString = localStorage.getItem('categories');
    if (isNullOrUndefined(catString)) {
      return undefined;
    }

    return JSON.parse(catString) as Category[];
  }

  public setCategoriesCache(categores: Category[]) {
    localStorage.setItem('categories', JSON.stringify(categores));
  }

}