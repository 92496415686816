import { connect } from 'react-redux';

import { RootState } from '../reducers';

import CategoryView from './CategoryView';

const mapStateToProps = (state: RootState) => ({
  categories: state.category.categories
});

export default connect(mapStateToProps)(CategoryView);