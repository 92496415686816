import theme from '../../../theme';

const styles = {
    root: {
        width: 700,
        backgroundColor: 'theme.palette.background.paper',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 500,
    },
    fab: {
        marginRight: theme.spacing(1),
      },
};

export default styles;