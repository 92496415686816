import axios from 'axios';
import decode from 'jwt-decode'

import { Tokens } from '../interfaces/tokens.interface';
import { backendUrl } from '../config';

const authUrl = `${backendUrl}/oauth/token`;

const baseHeader = {
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
  client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET
}

export default class AuthService {
  public async signIn(username: string, password: string) {
    let result;
  
    try {
      result = await axios.post<any>(authUrl, {
        ...baseHeader,
        grant_type: 'password',
        username,
        password
      });
    } catch (error) {
      throw new Error(error);
    }
  
    const { access_token, refresh_token, expires_in } = result.data;
  
    const user: Tokens = { access_token, refresh_token, expires: (Date.now() + expires_in) };
  
    this.setUserStorage(user);
  
    return user;
  }
  
  
  public signOut() {
    this.removeUserStorage();
  }
  
  public async refresh() {
    let response;
    
    if (this.authenticated()) {
      throw new Error('Refresh token expired');
    }

    let user = this.getUserStorage() as Tokens;
  
    try {
      response = await axios.post(authUrl, {
        ...baseHeader,
        grant_type: 'refresh_token',
        refresh_token: user.refresh_token
      });
    } catch (error) {
      throw new Error('Error refreshing token');
    }
  
    const { access_token, refresh_token, expires_in } = response.data;
  
    user = { access_token, refresh_token, expires: (Date.now() / 1000 + expires_in) };
  
    this.setUserStorage(user);
  
    return user;
  }
  
  public authenticated() {
    const user = this.getUserStorage();
    
    if (!user) {
      return false;
    }
  
    const { expires } = user;
  
    if (expires < Date.now() / 1000) {
      return false;
    }
  
    return true;
  
  }
  
  public async getTokens() {
    if (!this.authenticated()) {
      throw new Error('User not logged in');
    }
  
    let user = this.getUserStorage() as Tokens;
  
    if (this.tokenExpired(decode(user.access_token))) {
        user = await this.refresh();
    }
  
    return user;
  }
  
  private tokenExpired(token: any) {
    return token.exp < Date.now() / 1000;
  }
  
  private setUserStorage(user: Tokens) {
    localStorage.setItem('user', JSON.stringify(user));
  }
  
  private getUserStorage() {
    const userString = localStorage.getItem('user');
    
    if (userString !== null) {
      return JSON.parse(userString) as Tokens;
    }

    return undefined;
  }
  
  private removeUserStorage() {
    localStorage.removeItem('user');
  }
}