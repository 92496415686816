import React, { FC } from 'react';
import AdminView from './AdminView';
import { Route } from 'react-router-dom';
import { Content, ToolbarSpacer } from '../components/Common';

const MainView: FC = () => (
  <Content>
    <ToolbarSpacer />
    <Route path="/admin" component={AdminView}/>
  </Content>
);

export default MainView;