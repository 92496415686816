import React, { Component } from 'react';

import TagList from '../components/Admin/Tag/TagList';
import Tag from '../services/models/tag.model';
import { isNullOrUndefined } from 'util';
import TagForm from '../components/Admin/Tag/TagForm';
import TagService from '../services/tag.service';

interface State {
    tags: Tag[],
    editing?: Tag,
    selectedTagType?: Tag,
    showForm: boolean,
}

class TagView extends Component<{}, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            tags: [],
            editing: undefined,
            selectedTagType: undefined,
            showForm: false,
        }

        this.editHandler = this.editHandler.bind(this);
        this.newHandler = this.newHandler.bind(this);
    }

    componentWillMount() {
        this.fetchTags();
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    async fetchTags() {
        const tagService = new TagService();

        let result;

        try {
            result = await tagService.getTags();
            this.setState({ tags: result })
        } catch (error) {
            console.log(error)
        }
    }

    editHandler(id: number) {
        if (!isNullOrUndefined(this.state.tags)) {
            this.setState({
                editing: this.state.tags.find(tag => tag.id === id),
                showForm: true
            });
        }
        console.log(this.state);
    }

    newHandler(parent_id?: number) {
        if (!isNullOrUndefined(this.state.tags)) {
            this.setState({
                selectedTagType: this.state.tags.find(tag => tag.id === parent_id),
                showForm: true
            });
        }
        console.log(this.state);
    }

    removeForm() {
        this.setState({ showForm: false })
        this.setState({
            editing: undefined,
            selectedTagType: undefined
        })
        this.fetchTags();
    }

    tagForm() {

        if (this.state.showForm) {

            if (!isNullOrUndefined(this.state.selectedTagType) && !isNullOrUndefined(this.state.editing)) {
                console.log('Both selectedParent and editing are set, this shouldnt happen..');
                return (<div>Error</div>);
            } else {
                return (
                    <TagForm editing={this.state.editing} parent={this.state.selectedTagType} resetFunc={() => this.removeForm()} />
                );
            }

        }
        else {
            return null;
        }

    }


    render() {
        let helpText;
        if (this.state.showForm) {
            if (!isNullOrUndefined(this.state.selectedTagType)) {
                helpText = `Lag ny tag av typen ${this.state.selectedTagType.name}.`
            } else if (!isNullOrUndefined(this.state.editing)) {
                helpText = `Endre taggen ${this.state.editing.name} med id ${this.state.editing.id}`
            } else {
                helpText = `Lag ny TagType`
            }
        }
        return (
            <div>
                <TagList tags={this.state.tags} editHandler={this.editHandler} newHandler={this.newHandler} />
                <h1>{helpText}</h1>
                {this.tagForm()}
            </div>
        );
    }
}

export default TagView;