import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { styled } from '@material-ui/styles';

import Header from './components/Layout/Header';
import DrawerNavigation from './components/Layout/DrawerNavigation';
import MainView from './views/MainView';


const Content = styled('div')({
  display: 'flex'
}) 

interface Props {
  getCategories: () => void
}

class App extends Component<Props> {

  componentWillMount() {
    const { getCategories } = this.props;

    getCategories();
  }

  render() {
      return (
        <Router>
          <Content>
            <Header />
            <DrawerNavigation />
            <MainView />
          </Content>
        </Router>
      );
  }
}

export default App;
